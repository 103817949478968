class HttpClient {

    subscribeToReleaseDateNotification = async ({firstName, lastName, email, city, state, isSubscribedToNewsLetter}) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_BACKEND_URL}/webapp/`, {
                method: "POST",
                headers: {"Content-Type": "application/json",},
                body: JSON.stringify({
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    city: city,
                    state: state,
                    subscribed_to_newsletter: isSubscribedToNewsLetter
                })
            });
            if (result.status / 100 !== 2) {
                console.log(result);
                throw new Error('Non-200 return status')
            }
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    sendEmail = async ({message, fromEmail, name}) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_BACKEND_URL}/webapp/email`, {
                method: "POST",
                headers: {"Content-Type": "application/json",},
                body: JSON.stringify({
                    name: name,
                    email: fromEmail,
                    message: message,
                    subject: 'Landing Page Email Form',
                })
            });
            if (result.status / 100 !== 2) {
                console.log(result);
                throw new Error('Non-200 return status')
            }
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    // used for testing
    stall = async (stallTime = 2000) => {
        await new Promise(resolve => setTimeout(resolve, stallTime));
    }
}

export default HttpClient;
