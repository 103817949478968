import AboutUsTitle from '../../../assets/images/titles/AboutUs.png';
import Card from "./card/Card";

import classes from './AboutUs.module.css';
import {IdConstants} from "../../../constants/IdConstants";

import CarloHeadshot from '../../../assets/images/headshots/Carlo.png';
import KevHeadshot from '../../../assets/images/headshots/Kev.jpg';
import PeaceHeadshot from '../../../assets/images/headshots/Peace.png';
import RobHeadshot from '../../../assets/images/headshots/Rob.png';
import JohnHeadshot from '../../../assets/images/headshots/John.png';

const members = [
    {
        id: 1,
        name: 'Peace Drevitch',
        title: 'Co-Founder',
        description: 'Peace joins us from Pittsburgh, PA. He works as a Customer Success Partner at SAP, with a ' +
            'degree in Information Systems from Duquesne University. Having grown up playing soccer, he has always ' +
            'had a passion for the sport and is a dedicated supporter of Crystal Palace.',
        photo: PeaceHeadshot
    },
    {
        id: 2,
        name: 'Keven Duan',
        title: 'Co-Founder',
        description: 'Keven joins us from Seattle, WA where he works as a Business Development Manager at Keepe. ' +
            'He studied Economics/Chinese at Kenyon College and is an avid supporter of F.C. Barcelona.',
        photo: KevHeadshot
    },
    {
        id: 3,
        name: 'Robert Campbell',
        title: 'CTO/Engineer',
        description: 'Robert joins us from Atlanta, GA and has been an Arsenal fan for as long as he can remember. ' +
            'He formerly worked as a data scientist at Tangible AI and Viva Translate. Robert will be developing the ' +
            'backend services for our game day experience app.',
        photo: RobHeadshot
    },
    {
        id: 4,
        name: 'Carlo Berardelli',
        title: 'CTO/Engineer',
        description: 'Carlo Berardelli is a lifelong soccer fan and AC Milan supporter. Carlo currently works as a ' +
            'frontend engineer at SAP. Carlo will be building out the UI for our application.',
        photo: CarloHeadshot
    },
    {
        id: 5,
        name: 'John Magnus',
        title: 'Engineer',
        description: 'John Magnus is a fair weather Chelsea fan joining us from Seattle, WA. He works as software engineer and is ' +
            'operating as a full stack developer for Kickoff.',
        photo: JohnHeadshot
    }
]

const AboutUs = () => {
    return (
        <div id={IdConstants.AboutUs}>
            <div className={classes.Header}>
                <div className={classes.HeaderInner}>
                    <img src={AboutUsTitle} alt={'About us'} />
                    <div className={classes.HeaderText}>
                        At Kickoff, we're a team of dedicated former collegiate soccer players who share a passion for
                        the beautiful game. Our goal is to foster a vibrant and supportive community of fans while
                        enhancing the game day experience for soccer lovers everywhere.
                    </div>
                </div>
            </div>

            <div className={classes.Cards}>
                {members.map(member => (
                    <div className={classes.Card}>
                        <Card id={member.id}
                              name={member.name}
                              title={member.title}
                              description={member.description}
                              photo={member.photo}/>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AboutUs;
