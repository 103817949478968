import React from "react";
import classes from "./NavigationSlide.module.css";
import {IdConstants} from "../../../constants/IdConstants";


const Option = ({targetId, text, close}) => {

    return (
        <a href={`/#${targetId}`} className={classes.NavOption} onClick={close}>
            {text}
        </a>
    )
}

const NavigationSlide = ({isOpen, close}) =>  {

    return (
        <>
            {isOpen &&
            <div className={classes.Backdrop} onClick={close}/>
            }
            <div className={isOpen ? [classes.Container, classes.ContainerActive].join(' ') : classes.Container}>
                <div className={classes.Content}>
                    <Option targetId={IdConstants.HowItWorks} text={'How it works'} close={close}/>
                    <Option targetId={IdConstants.AboutUs} text={'About us'} close={close}/>
                    <Option targetId={IdConstants.FAQ} text={'FAQ'}  close={close}/>
                    <Option targetId={IdConstants.Download} text={'Download'}  close={close}/>

                    <div className={classes.Footer}>
                        <div className={classes.FooterOption}>
                            <a href="mailto:john@test.com?Subject=Hello%20John" target="_blank">john@test.com</a>
                        </div>
                        <div className={classes.FooterOption}>
                            Terms of Service
                        </div>
                        <div className={classes.FooterOption}>
                            Privacy Policy
                        </div>
                        <div className={classes.FooterOption}>
                            © 2023 Kickoff
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default NavigationSlide;
