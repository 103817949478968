import classes from './Card.module.css';
import CircleBlue from '../../../../assets/images/CircleBlue.png';
import CircleGreen from '../../../../assets/images/CircleGreen.png';

const Card = ({name, title, description, photo}) => {
    return (
        <div className={classes.Container}>
            <div className={classes.Left}>
                <img src={photo} alt={'Sample Headshot'}/>
                <div className={classes.Name}>
                    {name}
                </div>
                <div className={classes.Title}>
                    {title}
                </div>
            </div>

            <div className={classes.Right}>
                {description}
            </div>

            <img src={CircleBlue} className={classes.CircleBlue}/>
            <img src={CircleGreen} className={classes.CircleGreen}/>
        </div>
    )
}

export default Card;
