import classes from './Navbar.module.css';

import Logo from '../../assets/images/LogoNavbar.png';
import {IdConstants} from "../../constants/IdConstants";
import Hamburger from '../../assets/images/icons/Hamburger.png';
import {useCallback, useState} from "react";
import NavigationSlide from "./slide/NavigationSlide";

const NavOption = ({displayText, id}) => {
    return (
        <a className={classes.NavOption} href={`/#${id}`}>
            {displayText}
        </a>
    )
}

const Navbar = () => {

    const [isSideNavOpen, setIsSideNavOpen] = useState(false);

    const closeSideNav = useCallback(() => {
        setIsSideNavOpen(false);
    }, []);

    const openSideNav = useCallback(() => {
        setIsSideNavOpen(true);
    }, []);

    return (
        <div className={classes.Container}>
            <div className={classes.Left}>
                <img src={Logo} alt={'Kickoff'} className={classes.Logo}/>
            </div>
            <div className={classes.Right}>
                <NavOption displayText={'How it works'} id={IdConstants.HowItWorks}/>
                <NavOption displayText={'About us'} id={IdConstants.AboutUs}/>
                <NavOption displayText={'FAQ'} id={IdConstants.FAQ}/>
                <NavOption displayText={'Download'} id={IdConstants.Download}/>
            </div>
            <div className={classes.RightSmall}>
                <div className={classes.Hamburger} onClick={openSideNav}>
                    <img src={Hamburger} />
                </div>
            </div>

            <NavigationSlide isOpen={isSideNavOpen}
                             close={closeSideNav}/>
        </div>
    )
}

export default Navbar;
