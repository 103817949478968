import Popup from "../../components/popup/Popup";

import classes from './EmailUsPopup.module.css';
import CloseIcon from "../../assets/images/icons/Close.png";
import SubmitButton from "../../components/submit-button/SubmitButton";
import React, {useCallback, useState} from "react";
import useGlobalPopup from "../../hooks/useGlobalPopup";
import PopupSuccess from "../../components/popup-success/PopupSuccess";
import useHttpClient from "../../hooks/useHttpClient";

const validateInfo = (email, content) => {
    const errors = []

    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
        errors.push('Email');
    }

    if (!content.trim()) {
        errors.push('Content')
    }

    return errors;
}


const EmailUsPopup = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');

    const [validationErrors, setValidationErrors] = useState([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasSuccessfullySubmitted, setHasSuccessfullySubmitted] = useState(false);


    const {setOpenPopup} = useGlobalPopup();
    const {httpClient} = useHttpClient();

    const submit = useCallback(async () => {
        setHasSubmitted(true);
        const validationErrors = validateInfo(email, content);
        if (validationErrors.length > 0) {
            setValidationErrors(validationErrors);
            return;
        }

        setIsSubmitting(true);
        try {
            await httpClient.sendEmail({
                message: content,
                fromEmail: email,
                name: name
            })

            setHasSuccessfullySubmitted(true);
        } catch (e) {
            alert('Unexpected error, please try again.');
        }
        setIsSubmitting(false);
    }, [email, content, name]);

    const onNameChange = useCallback((e) => {
        setValidationErrors(validateInfo(e.target.value, content));
        setName(e.target.value);
    }, [email, content, name]);

    const onEmailChange = useCallback((e) => {
        setValidationErrors(validateInfo(e.target.value, content));
        setEmail(e.target.value);
    }, [email, content, name]);

    const onContentChange = useCallback((e) => {
        setValidationErrors(validateInfo(email, e.target.value));
        setContent(e.target.value);
    }, [email, content, name]);

    const shouldShowErrorMessage = useCallback(error => {
        return hasSubmitted && validationErrors.filter(err => err === error).length > 0;
    }, [hasSubmitted, validationErrors]);

    const close = () => {
        setOpenPopup(null);
    }


    return (
        <Popup>
            <div className={classes.Container}>
                {hasSuccessfullySubmitted && (
                    <PopupSuccess title={'Success'}
                                  subtitle={'We have successfully received your email and will get  back to you in the next few days'}
                                  submitButtonClassName={classes.SubmitButton}
                                  onSubmit={close}/>
                )}
                {!hasSuccessfullySubmitted && (
                    <>
                        <div className={classes.CloseIcon} onClick={close}>
                            <img src={CloseIcon} alt={'Close'}/>
                        </div>
                        <div className={classes.Title}>Email us</div>
                        <div className={classes.Subtitle}>
                            Enter your email content and address for us to get back to you at.
                        </div>

                        <div className={classes.Form}>
                            <div className={classes.FormFieldFull}>
                                <div>Your name</div>
                                <input type={'text'} placeholder={'Your name'} onChange={onNameChange} value={name}/>
                                {shouldShowErrorMessage('Name') && <div className={classes.Error}>Please enter your name</div>}
                            </div>

                            <div className={classes.FormFieldFull}>
                                <div>Your email address</div>
                                <input type={'text'} placeholder={'Your email address'} onChange={onEmailChange} value={email}/>
                                {shouldShowErrorMessage('Email') && <div className={classes.Error}>Please enter a valid email address</div>}
                            </div>

                            <div className={classes.FormFieldFull}>
                                <div>Email content</div>
                                <textarea placeholder={'Enter your email content'} onChange={onContentChange} value={content}/>
                                {shouldShowErrorMessage('Content') && <div className={classes.Error}>Please enter email content</div>}
                            </div>

                        </div>

                        <SubmitButton isFullWidth isSubmitting={isSubmitting} onClick={submit} className={classes.SubmitButton}>
                            Send email
                        </SubmitButton>
                    </>
                )}
            </div>
        </Popup>
    )
}

export default EmailUsPopup;
