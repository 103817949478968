import classes from './FrequentlyAskedQuestions.module.css';
import FrequentlyAskedQuestionsTitle from '../../../assets/images/titles/FrequentlyAskedQuestions.png';
import {useState} from "react";
import QuestionExpand from "./question-expand/QuestionExpand";
import {IdConstants} from "../../../constants/IdConstants";

const questions = [
    {
        id: 1,
        question: 'How do I find bars near me on Kickoff?',
        answer: 'You can use the search function within the app to find bars near you that are showing soccer games. ' +
            'Simply let us know what city you’re in and Kickoff will guide you to nearby bars.'
    },
    {
        id: 2,
        question: 'Is Kickoff available in my area?',
        answer: 'Kickoff is currently available in select cities in the United States, with plans to expand to ' +
            'additional locations in the future. Check the app to see what’s available, or let us know where we should come next!'
    },
    {
        id: 3,
        question: 'Can I suggest a bar or Partner with Kickoff?',
        answer: 'Yes! Kickoff is always looking for new bars to partner up and do business with. Contact Kickoff support ' +
            'to suggest a new partner or to inquire about partnership opportunities.'
    },
    {
        id: 4,
        question: 'Can I leave a review of the bar I visited through Kickoff?',
        answer: 'Yes, please! Let us know what your experience was like - your feedback will be shared with bars and ' +
            'users so that we all can keep growing the community the right way.'
    },
]

const FrequentlyAskedQuestions = () => {

    const [expandedQuestionId, setExpandedQuestionId] = useState('');

    const toggle = (questionId) => {
        if (expandedQuestionId === questionId) {
            setExpandedQuestionId(null);
        } else {
            setExpandedQuestionId(questionId)
        }
    }

    return (
        <div className={classes.Container} id={IdConstants.FAQ}>
            <div className={classes.Header}>
                <div className={classes.HeaderInner}>
                    <img src={FrequentlyAskedQuestionsTitle} alt={'Frequently asked questions'} />
                    <div className={classes.HeaderText}>
                        Frequently Asked Question by users
                    </div>
                </div>
            </div>

            <div className={classes.Questions}>
                {questions.map(question => (
                    <QuestionExpand question={question.question}
                                    key={question.id}
                                    answer={question.answer}
                                    isExpanded={expandedQuestionId === question.id}
                                    toggleExpansion={() => toggle(question.id)}/>
                ))}
            </div>

        </div>
    )
}

export default FrequentlyAskedQuestions;
