import Popup from "../../components/popup/Popup";
import CloseIcon from '../../assets/images/icons/Close.png';

import classes from './ReleaseDateNotificationPopup.module.css';
import useGlobalPopup from "../../hooks/useGlobalPopup";
import React, {useCallback, useState} from "react";
import {States} from "../../constants/States";
import AngleDownIcon from "../../assets/images/icons/AngleDown.png";
import SubmitButton from "../../components/submit-button/SubmitButton";
import PopupSuccess from "../../components/popup-success/PopupSuccess";
import CheckBox from "../../components/checkbox/CheckBox";
import useHttpClient from "../../hooks/useHttpClient";
import {validateReleaseDateNotificationForm} from './validator';

const ReleaseDateNotificationPopup = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [isSubscribedToNewsLetter, setIsSubscribedToNewsLetter] = useState(false);

    const [validationErrors, setValidationErrors] = useState([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasSuccessfullySubmitted, setHasSuccessfullySubmitted] = useState(false);

    const {setOpenPopup} = useGlobalPopup();
    const {httpClient} = useHttpClient();

    const close = useCallback(() => {
        setOpenPopup(null);
    }, []);

    const submit = useCallback(async () => {
        setHasSubmitted(true);
        const validationErrors = validateReleaseDateNotificationForm(firstName, lastName, email, city, state);
        if (validationErrors.length > 0) {
            setValidationErrors(validationErrors);
            return;
        }

        setIsSubmitting(true);
        try {
            await httpClient.subscribeToReleaseDateNotification({
                firstName: firstName,
                lastName: lastName,
                email: email,
                city: city,
                state: state,
                isSubscribedToNewsLetter: isSubscribedToNewsLetter
            });
            setHasSuccessfullySubmitted(true);
        } catch (e) {
            console.log('catching error here');
            alert('Unexpected error, please try again.')
        }
        setIsSubmitting(false);
    }, [firstName, lastName, email, city, state]);

    const shouldShowErrorMessage = useCallback(error => {
        return hasSubmitted && validationErrors.filter(err => err === error).length > 0;
    }, [hasSubmitted, validationErrors]);

    const onFirstNameChange = useCallback((e) => {
        setValidationErrors(validateReleaseDateNotificationForm(e.target.value, lastName, email, city, state));
        setFirstName(e.target.value);
    }, [lastName, email, city, state]);

    const onLastNameChange = useCallback((e) => {
        setValidationErrors(validateReleaseDateNotificationForm(firstName, e.target.value, email, city, state));
        setLastName(e.target.value);
    }, [firstName, email, city, state]);

    const onEmailChange = useCallback((e) => {
        setValidationErrors(validateReleaseDateNotificationForm(firstName, lastName, e.target.value, city, state));
        setEmail(e.target.value);
    }, [firstName, lastName, city, state]);

    const onCityChange = useCallback((e) => {
        setValidationErrors(validateReleaseDateNotificationForm(firstName, lastName, email, e.target.value, state));
        setCity(e.target.value);
    }, [firstName, lastName, email, state]);

    const onStateChange = useCallback((e) => {
        setValidationErrors(validateReleaseDateNotificationForm(firstName, lastName, email, city, e.target.value));
        setState(e.target.value);
    }, [firstName, lastName, email, city]);

    const onSubscriptionToggle = useCallback(() => {
        setIsSubscribedToNewsLetter(!isSubscribedToNewsLetter);
    }, [isSubscribedToNewsLetter]);



    return (
        <Popup onClickOutside={close}>
            <div className={classes.Container}>
                {hasSuccessfullySubmitted && (
                    <PopupSuccess title={'Success'}
                                  subtitle={'You will receive a notification when we officially launch the app!'}
                                  onSubmit={close}/>
                )}
                {!hasSuccessfullySubmitted && (
                    <>
                        <div className={classes.CloseIcon} onClick={close}>
                            <img src={CloseIcon} alt={'Close'}/>
                        </div>
                        <div className={classes.Title}>Launch Day Notification</div>
                        <div className={classes.Subtitle}>
                            Enter your information and we will notify you when our product officially launches!
                        </div>

                        <div className={classes.Form}>
                            <div className={classes.FormFieldHalf}>
                                <div>First name</div>
                                <input type={'text'} placeholder={'First name'} onChange={onFirstNameChange} value={firstName}/>
                                {shouldShowErrorMessage('FirstName') && <div className={classes.Error}>Please enter your first name</div>}
                            </div>
                            <div className={classes.FormFieldHalf}>
                                <div>Last name</div>
                                <input type={'text'} placeholder={'Last name'} onChange={onLastNameChange} value={lastName}/>
                                {shouldShowErrorMessage('LastName') && <div className={classes.Error}>Please enter your last name</div>}
                            </div>
                            <div className={classes.FormFieldFull}>
                                <div>Email</div>
                                <input type={'text'} placeholder={'Enter your email'} onChange={onEmailChange} value={email}/>
                                {shouldShowErrorMessage('Email') && <div className={classes.Error}>Please enter a valid email address</div>}
                            </div>
                            <div className={classes.FormFieldHalf}>
                                <div>City</div>
                                <input type={'text'} placeholder={'Enter the city you live in'} onChange={onCityChange} value={city}/>
                                {shouldShowErrorMessage('City') && <div className={classes.Error}>Please enter the city you live in</div>}
                            </div>
                            <div className={classes.FormFieldHalf}>
                                <div>State</div>
                                <div className={classes.SelectContainer}>
                                    <select onChange={onStateChange} value={state}>
                                        <option disabled selected value={''}>Choose a state</option>
                                        {Object.entries(States).map(
                                            ([key, value]) => (
                                                <option value={key}>
                                                    {value}
                                                </option>
                                            )
                                        )}
                                    </select>
                                    <img src={AngleDownIcon} className={classes.SelectIcon}/>
                                </div>
                                {shouldShowErrorMessage('State') && <div className={classes.Error}>Please enter the state you live in</div>}
                            </div>
                            <div className={classes.FormFieldFull}>
                                <CheckBox isActive={isSubscribedToNewsLetter} labelText={'subscribe to our news letter!'} toggle={onSubscriptionToggle}/>
                            </div>
                        </div>

                        <SubmitButton isFullWidth isSubmitting={isSubmitting} onClick={submit}>
                            Notify me!
                        </SubmitButton>
                    </>
                )}
            </div>
        </Popup>
    )
}

export default ReleaseDateNotificationPopup;
