import Navbar from "../../components/navbar/Navbar";

import classes from './Landing.module.css';
import Main from "./main/Main";
import HowItWorks from "./how-it-works/HowItWorks";
import AboutUs from "./about-us/AboutUs";
import FrequentlyAskedQuestions from "./faq/FrequentlyAskedQuestions";
import Download from "./download/Download";
import Footer from "../../components/footer/Footer";

const Landing = () => {
    return (
        <div className={classes.Container}>
            <Navbar />
            <Main />
            <HowItWorks />
            <AboutUs />
            <FrequentlyAskedQuestions />
            <Download />
            <Footer />
        </div>
    )
}

export default Landing;
