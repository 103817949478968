export const validateReleaseDateNotificationForm = (firstName, lastName, email, city, state) => {
    const errors = []
    if (!firstName.trim()) {
        errors.push('FirstName');
    }

    if (!lastName.trim()) {
        errors.push('LastName');
    }

    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
        errors.push('Email');
    }

    if (!city.trim()) {
        errors.push('City')
    }

    if (!state.trim()) {
        errors.push('State')
    }
    return errors;
}
