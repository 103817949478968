import classes from './Main.module.css';
import Logo from '../../../assets/images/LogoLarge.png';
import AppPreview from '../../../assets/images/previews/App.png';
import AppleStoreDownloadButton from '../../../assets/images/AppleStoreDownloadButton.png';
import GooglePlayStoreDownloadButton from '../../../assets/images/GooglePlayStoreDownloadButton.png';
import useGlobalPopup, {popups} from "../../../hooks/useGlobalPopup";
import {useCallback} from "react";

const Main = () => {

    const {setOpenPopup} = useGlobalPopup();

    const openReleaseDatePopup = useCallback(() => {
        setOpenPopup(popups.ReleaseDateNotification);
    }, []);

    return (
        <div className={classes.Container}>
            <div className={classes.Left}>
                <div>
                    <img src={Logo} alt={'Kickoff'} className={classes.Logo}/>
                    <div className={classes.Text}>
                        <div className={classes.PrimaryText}>
                            Your Ticket to the Ultimate Gameday Experience.
                        </div>
                        <div className={classes.SecondaryText} onClick={openReleaseDatePopup}>
                            Notify me on release date
                        </div>
                    </div>
                    <div className={classes.ActionButtons}>
                        <img src={AppleStoreDownloadButton} onClick={openReleaseDatePopup}/>
                        <img src={GooglePlayStoreDownloadButton} onClick={openReleaseDatePopup}/>
                    </div>
                </div>
            </div>
            <div className={classes.Right}>
                <img src={AppPreview} alt={'App preview'} />
            </div>
        </div>
    )
}

export default Main;
