import classes from './CheckBox.module.css'
import Check from '../../assets/images/icons/Check.png';

const CheckBox = ({isActive, labelText, toggle}) => {
    return (
        <div className={classes.Container} onClick={toggle}>
            <div className={isActive ? classes.BoxActive : classes.Box}>
                {isActive && (
                    <img src={Check} />
                )}
            </div>
            {!!labelText && (
                <div className={classes.Label}>
                    {labelText}
                </div>
            )}
        </div>
    )
}

export default CheckBox;
