import classes from './Download.module.css';
import DownloadTitle from '../../../assets/images/titles/Download.png';
import DownloadPreview from '../../../assets/images/previews/Download.png';
import AppleStoreDownloadButton from "../../../assets/images/AppleStoreDownloadButton.png";
import GooglePlayStoreDownloadButton from "../../../assets/images/GooglePlayStoreDownloadButton.png";
import CircleBlue from "../../../assets/images/CircleBlue.png";
import CircleGreen from "../../../assets/images/CircleGreen.png";
import {IdConstants} from "../../../constants/IdConstants";
import useGlobalPopup, {popups} from "../../../hooks/useGlobalPopup";
import {useCallback} from "react";

const Download = () => {

    const {setOpenPopup} = useGlobalPopup();

    const openReleaseDatePopup = useCallback(() => {
        setOpenPopup(popups.ReleaseDateNotification);
    }, []);

    return (
        <div className={classes.Container} id={IdConstants.Download}>
            <div className={classes.Header}>
                <div className={classes.HeaderInner}>
                    <img src={DownloadTitle} alt={'Download'}/>
                    <div className={classes.HeaderText}>
                        Download our app below
                    </div>
                </div>
            </div>

            <div className={classes.Content}>
                <div className={classes.Left}>
                    <div>
                        <div className={classes.Title}>Download the app</div>
                        <div className={classes.Subtitle}>
                            Your Ticket to the Ultimate Gameday Experience.
                        </div>
                        <div className={classes.ActionButtons}>
                            <img src={AppleStoreDownloadButton} onClick={openReleaseDatePopup}/>
                            <img src={GooglePlayStoreDownloadButton} onClick={openReleaseDatePopup}/>
                        </div>
                    </div>
                </div>
                <div className={classes.Right}>
                    <img src={DownloadPreview} alt={'Download'} />
                </div>

                <img src={CircleBlue} className={classes.CircleBlue}/>
                <img src={CircleGreen} className={classes.CircleGreen}/>
            </div>
        </div>
    )
}

export default Download;
