import classes from './PopupSuccess.module.css';
import SuccessImage from '../../assets/images/Success.png';
import SubmitButton from "../submit-button/SubmitButton";

const PopupSuccess = ({title, subtitle, submitButtonClassName, onSubmit}) => {
    return (
        <div className={classes.Container}>
            <img src={SuccessImage} alt={'Success'} />
            <div className={classes.Title}>
                {title}
            </div>
            <div className={classes.Subtitle}>
                {subtitle}
            </div>
            <SubmitButton className={submitButtonClassName} onClick={onSubmit}>
                Ok
            </SubmitButton>
        </div>
    )
}

export default PopupSuccess;
