import classes from './HowItWorks.module.css';
import HowItWorksTitle from '../../../assets/images/titles/HowItWorks.png';
import CreateProfilePreview from '../../../assets/images/previews/CreateProfile.png';
import FindABarPreview from '../../../assets/images/previews/FindABar.png';
import FanFeedbackPreview from '../../../assets/images/previews/FanFeedback.png';
import FindYourMatesPreview from '../../../assets/images/previews/FindYourMates.png';

import {IdConstants} from "../../../constants/IdConstants";

const HowItWorks = () => {
    return (
        <div className={classes.Container} id={IdConstants.HowItWorks}>
            <div className={classes.Header}>
                <div className={classes.HeaderInner}>
                    <img src={HowItWorksTitle} alt={'How it works?'}/>
                </div>
            </div>


            <div className={classes.Section}>
                <div className={classes.SectionText}>
                    <div>
                        <div className={classes.SectionTitle}>Create a profile</div>
                        <div className={classes.SectionSubtitle}>Curate your ideal match day by selecting your favorite teams, leagues, and competitions.</div>
                    </div>
                </div>
                <div className={classes.SectionImage}>
                    <img src={CreateProfilePreview} alt={'Create profile'} />
                </div>
            </div>

            <div className={classes.Section}>
                <div className={classes.SectionImage}>
                    <img src={FindABarPreview} alt={'Find a bar'} />
                </div>
                <div className={classes.SectionText}>
                    <div>
                        <div className={classes.SectionTitle}>Find a Bar, Wherever You Are</div>
                        <div className={classes.SectionSubtitle}>Discover nearby venues that are streaming the biggest matchups of the week.</div>
                    </div>
                </div>
            </div>

            <div className={classes.Section}>
                <div className={classes.SectionText}>
                    <div>
                        <div className={classes.SectionTitle}>Fan Feedback</div>
                        <div className={classes.SectionSubtitle}>Our Fan Feedback will point you where you want to go & help you find your desired atmosphere.</div>
                    </div>
                </div>
                <div className={classes.SectionImage}>
                    <img src={FanFeedbackPreview} alt={'Real time updates'} />
                </div>
            </div>


            <div className={classes.Section}>
                <div className={classes.SectionImage}>
                    <img src={FindYourMatesPreview} alt={'Find Your Mates'} />
                </div>
                <div className={classes.SectionText}>
                    <div>
                        <div className={classes.SectionTitle}>Find Your Mates</div>
                        <div className={classes.SectionSubtitle}>Connect with a community of people who share your passion for sports and create meaningful relationships while enjoying the game.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;
