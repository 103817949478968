import React from "react";
import {GlobalPopupProvider} from "./hooks/useGlobalPopup";
import AppInner from "./AppInner";
import {HttpClientProvider} from "./hooks/useHttpClient";

function App() {
  return (
      <HttpClientProvider>
          <GlobalPopupProvider>
              <AppInner />
          </GlobalPopupProvider>
      </HttpClientProvider>
  );
}

export default App;
