import {Route, Routes} from "react-router-dom";
import Landing from "./pages/landing/Landing";
import React from "react";
import useGlobalPopup, {popups} from "./hooks/useGlobalPopup";
import ReleaseDateNotificationPopup from "./popups/release-date-notification/ReleaseDateNotificationPopup";
import EmailUsPopup from "./popups/email-us/EmailUsPopup";

const AppInner = () => {
    const {openPopup} = useGlobalPopup();

    return (
        <>
            <Routes>
                <Route path='/' element={<Landing />} />
            </Routes>

            {openPopup === popups.EmailUs && <EmailUsPopup />}
            {openPopup === popups.ReleaseDateNotification && <ReleaseDateNotificationPopup />}
        </>
    );
}

export default AppInner;
