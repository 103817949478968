import classes from './Footer.module.css';
import FacebookIcon from '../../assets/images/icons/Facebook.png';
import MessageIcon from '../../assets/images/icons/Message.png';
import InstagramIcon from '../../assets/images/icons/Instagram.png';
import LinkedInIcon from '../../assets/images/icons/LinkedIn.png';
import CircleBlue from "../../assets/images/CircleBlue.png";
import CircleGreen from "../../assets/images/CircleGreen.png";
import {useCallback} from "react";
import useGlobalPopup, {popups} from "../../hooks/useGlobalPopup";

const Footer = () => {

    const {setOpenPopup} = useGlobalPopup();

    const openEmailPopup = useCallback(() => {
        setOpenPopup(popups.EmailUs);
    }, []);

    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Left}>
                    <div className={classes.Title}>
                        Contact Us
                    </div>
                    <div className={classes.Subtitle}>
                        We're here to help! If you have any questions, comments, or concerns about our products or services,
                        please don't hesitate to get in touch with us.
                    </div>

                    <div className={classes.EmailButton} onClick={openEmailPopup}>
                        Email us
                        <img src={MessageIcon} alt={'Email us'} />
                    </div>

                    <div className={classes.SocialIcons}>
                        <a href={'https://www.facebook.com/profile.php?id=100091968934521'} target={'_blank'}>
                            <img src={FacebookIcon} alt={'Facebook'}/>
                        </a>
                        <a href={'https://www.instagram.com/kickofftheapp/'} target={'_blank'}>
                            <img src={InstagramIcon} alt={'Instagram'}/>
                        </a>
                        <a href={'https://www.linkedin.com/company/kickoffapp/'} target={'_blank'}>
                            <img src={LinkedInIcon} alt={'LinkedIn'} />
                        </a>
                    </div>
                </div>

                <div className={classes.Right}>
                    <div>Privacy Policy</div>
                    <div className={classes.Seperator}>|</div>
                    <div>Terms of Service</div>
                </div>

            </div>

            <img src={CircleBlue} className={classes.CircleBlue}/>
            <img src={CircleGreen} className={classes.CircleGreen}/>
        </div>
    )
}

export default Footer;
